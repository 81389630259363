import React from 'react';
import { connect } from 'react-redux';

import Button from '../Button/index';

import { toggleCamera, takePicture } from '../../redux/actions';
import Snapshot from '../Button/Snapshot';

const CameraControls = (props) => (
  <>
    <Button icon="back" onClick={() => props.toggleCamera()} />
    <Snapshot onClick={() => props.takePicture()} />
    <Button icon="empty" />
  </>
);

export default connect(
  null,
  {
    toggleCamera,
    takePicture,
  },
)(CameraControls);
