import React from 'react';
import {
  Vector2,
  Vector3,
  Scene,
  PerspectiveCamera,
  WebGLRenderer,
  LineSegments,
  AmbientLight,
  DirectionalLight,
  PlaneBufferGeometry,
  MeshBasicMaterial,
  MathUtils,
  Fog,
  Group,
} from 'three';

import { WEBGL } from 'three/examples/jsm/WebGL';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass';
import { VRButton } from 'three/examples/jsm/webxr/VRButton';
import { isMobile } from 'react-device-detect';

import {
  autoPlay as TweenAutoplay,
  Easing,
  Tween,
} from 'es6-tween';

import Palette from '../../libs/Palette/index';
import Base from './Base';

const BLOOM = {
  ANIMATE: false,
  EXP: 1,
  STR: 0.3,
  THRES: 0,
  RAD: 0.05,
};

PlaneBufferGeometry.prototype.toGrid = function toGrid() {
  const segmentsX = this.parameters.widthSegments || 1;
  const segmentsY = this.parameters.heightSegments || 1;
  const indices = [];
  for (let i = 0; i < segmentsY + 1; i += 1) {
    let index11 = 0;
    let index12 = 0;
    for (let j = 0; j < segmentsX; j += 1) {
      index11 = (segmentsX + 1) * i + j;
      index12 = index11 + 1;
      const index21 = index11;
      const index22 = index11 + (segmentsX + 1);
      indices.push(index11, index12);
      if (index22 < ((segmentsX + 1) * (segmentsY + 1) - 1)) {
        indices.push(index21, index22);
      }
    }
    if ((index12 + segmentsX + 1) <= ((segmentsX + 1) * (segmentsY + 1) - 1)) {
      indices.push(index12, index12 + segmentsX + 1);
    }
  }
  this.setIndex(indices);
  return this;
};
export default class GlobeHome extends Base {
  constructor(...args) {
    super(...args);
    this.renderer = null;
    this.scene = null;
    this.camera = null;
    this.earth = null;
    this.globe = new Group();
    this.el = React.createRef();
  }

  componentDidMount() {
    super.componentDidMount();
    const {
      cameraTarget,
      xrEnabled,
    } = this.props;

    this.scene = new Scene();
    this.scene.fog = new Fog(Palette.bgHaze, 250 / 4, 250 / 2);

    const pixelRatio = 1;// window.devicePixelRatio > 2 ? 2 : window.devicePixelRatio;

    if (WEBGL.isWebGL2Available() === true) {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('webgl2', { alpha: false });
      this.renderer = new WebGLRenderer({
        canvas,
        context,
        antialias: true,
      });
    } else {
      this.renderer = new WebGLRenderer({
        antialias: true,
      });
    }

    this.renderer.setClearColor(Palette.bgHaze, 1);
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.renderer.setPixelRatio(pixelRatio);
    this.el.current.appendChild(this.renderer.domElement);

    if (xrEnabled) {
      document.body.appendChild(VRButton.createButton(this.renderer));
      this.renderer.xr.enabled = true;
    }

    this.renderer.shadowMap.enabled = true;

    this.camera = new PerspectiveCamera(
      50,
      window.innerWidth / window.innerHeight,
      0.001,
      250,
    );

    // const controls = new OrbitControls(this.camera, this.renderer.domElement);
    // controls.enablePan = false;

    switch (cameraTarget) {
      case 'side':
        this.camera.position.set(-1.75, 0.75, -2.7);
        this.camera.lookAt(new Vector3(-1.75, 0.75, 0));
        break;
      default:
        this.camera.position.x = 0;
        this.camera.position.z = -10;
        this.camera.lookAt(new Vector3(0, 0, 0));
        break;
    }

    // Lights
    const ambientLight = new AmbientLight(0xaaaaaa, 0.75);
    this.scene.add(ambientLight);

    const sunlight = new DirectionalLight(0xffffff, 1);
    sunlight.position.set(-10, 10, -10);
    sunlight.castShadow = true;
    this.scene.add(sunlight);

    this.plane = new LineSegments(
      new PlaneBufferGeometry(250, 250, 128, 128).toGrid(),
      new MeshBasicMaterial({
        color: 0xffffff,
        // wireframe: true,
        // wireframeLinewidth: 3,
        opacity: 0.4,
        transparent: true,
      }),
    );

    this.scene.add(this.plane);
    this.plane.position.y = -10;
    this.plane.rotateX(MathUtils.degToRad(-90));

    // Post processing
    const renderPass = new RenderPass(this.scene, this.camera);
    const bloomPass = new UnrealBloomPass(
      new Vector2(window.innerWidth, window.innerHeight),
      1.5,
      0.4,
      0.85,
    );

    bloomPass.threshold = BLOOM.THRES;
    bloomPass.strength = BLOOM.STR;
    bloomPass.radius = BLOOM.RAD;
    this.renderer.toneMappingExposure = BLOOM.EXP;

    const composer = new EffectComposer(this.renderer);

    composer.addPass(renderPass);
    composer.addPass(bloomPass);

    const speed = isMobile ? 0.024 : 0.012;

    const render = (t) => {
      super.beforeRender();
      const time = t / 2000;

      this.plane.material.opacity = (Math.cos(time) / 10) + 0.25;

      if (this.earth) {
        this.globe.rotateY(MathUtils.degToRad(speed));
      }

      // controls.update();
      // composer.render();
      this.renderer.render(this.scene, this.camera);

      super.afterRender();
    };

    this.renderer.setAnimationLoop(render);

    // Mouse and resize events
    const onWindowResize = () => {
      this.camera.aspect = window.innerWidth / window.innerHeight;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(window.innerWidth, window.innerHeight);

      composer.reset();
    };
    window.addEventListener('resize', onWindowResize, false);

    // // Adds GUI stuff
    // const gui = super.gui();

    // const guiBloom = gui.addFolder('Bloom Effect');
    // guiBloom.add(this.renderer, 'toneMappingExposure', 0, 1).step(0.001).name('Exposure').listen();
    // guiBloom.add(bloomPass, 'threshold', 0, 2).step(0.001).name('Cut threshold');
    // guiBloom.add(bloomPass, 'strength', 0, 2).step(0.1).name('Strength').listen();
    // guiBloom.add(bloomPass, 'radius', 0, 2).step(0.1).name('Radius').listen();
    // guiBloom.add(BLOOM, 'ANIMATE').name('Animate bloom');
    // gui.close();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    this.renderer.setAnimationLoop(null);
    this.renderer.domElement.remove();
  }

  componentDidUpdate(prevProps) {
    const { globe: globePrev } = prevProps;
    const { globe } = this.props;

    // If first instantiating the globe
    if (globePrev === null && globe) {
      this.earth = globe;

      const params = {
        x: 0.0001,
        y: 0.0001,
        z: 0.0001,
      };

      this.globe.scale.set(params.x, params.y, params.z);
      this.globe.rotateY(MathUtils.degToRad(-125));
      this.globe.add(this.earth);
      this.scene.add(this.globe);

      TweenAutoplay(true);

      new Tween(params)
        .to({
          x: 1, y: 1, z: 1,
        }, 750)
        .easing(Easing.Quadratic.Out)
        .on('update', ({
          x, y, z,
        }) => {
          this.globe.scale.set(x, y, z);
        })
        .delay(500)
        .start();
    }
  }

  render() {
    return (
      <>
        <div id="three" ref={this.el} />
      </>
    );
  }
}
