import React, { Component } from 'react';
import {
  Color,
  MeshLambertMaterial,
  TextureLoader,
  FrontSide,
  AudioLoader,
  MathUtils,
} from 'three';
import {
  BrowserView,
  MobileView,
  isIOS,
} from 'react-device-detect';
import ReactGA from 'react-ga';
import styled from 'styled-components';
import Fullscreen from 'react-full-screen';
import { Row, Col } from 'react-grid-system';

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';

import GlobeHome from '../Three/GlobeHome';
import GlobeAR from '../Three/GlobeAR';
import Loading from '../Loading';
import Interface from '../Interface';
import Logo from '../Logo';

import Palette from '../../libs/Palette';

const LogoContainer = styled.div`
  z-index: 1000;
  position: fixed;
  max-width: 640px;
  width: auto;
  top: 0;
  right: 0;
  padding: 4rem;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
`;

export default class Home extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      compatible: true,
      model: null,
      biomes: null,
      playing: false,
      fullscreen: false,
      memory: false,
    };

    this.startExperience = () => {
      ReactGA.event({
        category: 'app',
        action: 'experience',
        label: 'start',
      });

      this.setState({
        playing: true,
        fullscreen: true,
      });
    };

    this.messageWrapper = (message) => {
      const Link = <a href="https://earthdayar.com/">earthdayar.com</a>;

      return (
        <LogoContainer>
          <Row>
            <Col xs={12}>
              <Logo />
            </Col>
          </Row>
          <BrowserView>
            <Row>
              <Col xs={12} sm={5}>
                <Image alt="AR Earth Day QR" src="/assets/images/qr.svg" />
              </Col>
              <Col xs={12} sm={7}>
                <p>
                  {'You need an AR compatible mobile device for the Earth Day AR Experience, please go to '}
                  {Link}
                  {' on your device or scan the QR code.'}
                </p>
              </Col>
            </Row>
          </BrowserView>
          <MobileView>
            <Row>
              <Col xs={12} sm={12}>{message}</Col>
            </Row>
          </MobileView>
        </LogoContainer>
      );
    };

    this.renderNonARBrowser = () => {
      const Link = <a href="https://earthdayar.com/">earthdayar.com</a>;

      const browser = isIOS ? 'Safari' : 'Chrome';

      const message = (
        <p>
          {'This Augmented Reality experience is currently not supported in this browser, '}
          {`please copy and paste the following link into ${browser} to start - `}
          {Link}
        </p>
      );

      return this.messageWrapper(message, false);
    };


    this.renderLowMemoryBrowser = () => {
      const message = (
        <>
          <p>
            Your device is low on memory, you can either restart your device or try to
            close all your background application and try loading this page again.
          </p>
          <p>
            If you still receive this message you might need to use a more up to date device.
          </p>
        </>
      );

      return this.messageWrapper(message, false);
    };
  }

  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search, ['eyekandy', 'internal']);

    const load = () => {
      const { XR8 } = window;
      const compatible = XR8.XrDevice.isDeviceBrowserCompatible();
      this.setState({
        compatible,
      });

      ReactGA.event({
        category: 'device',
        action: 'webar_compatible',
        label: String(compatible),
        nonInteractive: true,
      });


      const loader = new GLTFLoader();
      const loaderError = (err) => {
        // eslint-disable-next-line no-console
        console.log(err);
        this.setState({
          loading: false,
          memory: true,
        });

        ReactGA.event({
          category: 'error',
          action: 'device',
          label: 'memory_exhausted',
          nonInteractive: true,
        });
      };

      const dracoLoader = new DRACOLoader();
      dracoLoader.setDecoderPath(`${process.env.PUBLIC_URL}/draco/gltf/`);
      dracoLoader.preload();

      loader.setDRACOLoader(dracoLoader);

      const texture = new TextureLoader().load('/assets/textures/earth/earth-green.jpg');
      texture.flipY = false;

      const earthMaterial = new MeshLambertMaterial({
        color: 0xffffff,
        map: texture,
        side: FrontSide,
      });

      const files = {
        globe: `${process.env.PUBLIC_URL}/assets/models/globe.glb`,
        biomes: `${process.env.PUBLIC_URL}/assets/models/biomes.glb`,
        audios: [
          'ocean-bg',
          'ocean-interaction',
          'freshwater-bg',
          'freshwater-interaction',
          'chaparral-bg',
          'chaparral-interaction',
          'tropicalrainforest-bg',
          'tropicalrainforest-interaction',
          'temperaterainforest-bg',
          'temperaterainforest-interaction',
        ],
      };
      const audioBuffers = {};

      loader.load(files.globe, (globeglb) => {
        // Extrapolate object group
        ReactGA.event({
          category: 'loaders',
          action: 'model_loaded',
          label: 'earth',
          nonInteractive: true,
        });

        const { scene: globeScene } = globeglb;
        const [globe] = globeScene.children;

        // Extrapolate children meshes
        const [ocean, earth] = globe.children;

        // Set ocean transparency on material
        ocean.scale.set(0.97, 0.97, 0.97);
        ocean.rotateY(MathUtils.degToRad(45)); // Quirk to hide mesh joins
        ocean.material.side = FrontSide;
        ocean.material.transparent = true;
        ocean.material.opacity = 0.85;
        ocean.material.color = new Color(Palette.deepBlue);

        earth.material = earthMaterial;

        this.setState({
          model: globeScene,
        });

        if (compatible) {
          const audioLoader = new AudioLoader();

          files.audios.forEach((file) => {
            audioLoader.load(`${process.env.PUBLIC_URL}/assets/sounds/${file}.mp3`, (buffer) => {
              audioBuffers[file] = buffer;

              ReactGA.event({
                category: 'loaders',
                action: 'audio_loaded',
                label: file,
                nonInteractive: true,
              });
            });
          });

          loader.load(files.biomes, (biomesglb) => {
            const { scene: biomesScene, animations } = biomesglb;
            const biomes = {
              ocean: [],
              temperaterainforest: [],
              tropicalrainforest: [],
              chaparral: [],
              freshwater: [],
            };

            ReactGA.event({
              category: 'loaders',
              action: 'model_loaded',
              label: 'biomes',
              nonInteractive: true,
            });

            const scales = {};
            const rotations = {};

            const biomeRegex = /biome_([a-z]*)_/i;

            biomesScene.children.forEach((biome) => {
              biome.children.forEach((obj) => {
                const matches = obj.name.match(biomeRegex);
                if (matches && matches[1]) {
                  const biomeName = matches[1];
                  biomes[biomeName].push(obj);

                  // Fast copies
                  const { x: xS, y: yS, z: zS } = obj.scale;
                  scales[obj.name] = JSON.parse(JSON.stringify({ x: xS, y: yS, z: zS }));

                  const { x: xR, y: yR, z: zR } = obj.rotation;
                  rotations[obj.name] = JSON.parse(JSON.stringify({ x: xR, y: yR, z: zR }));
                }
                obj.scale.set(0.0000001, 0.0000001, 0.0000001);
                // eslint-disable-next-line no-param-reassign
                obj.visible = false;
              });
            });

            biomesScene.userData = {
              biomes,
              scales,
              rotations,
              animations,
              audioBuffers,
            };

            this.setState({
              loading: false,
              biomes: biomesScene,
            });
          }, null, loaderError);
        } else {
          this.setState({
            loading: false,
          });
        }
      }, null, loaderError);
    };

    window.onload = () => {
      if (window.XRExtras) {
        load();
      } else {
        // If 8thwall library hasn't still loaded, wait for it.
        window.addEventListener('xrextrasloaded', load);
      }

      /**
       * The following chunk reads if the navigator XR is supported (chrome only for now)
       * and calls the appropriate loader.
       *
       * Integrate when it's fully supported, for now we fallback on 8thWall
       *  if ('xr' in navigator) {
       *    navigator.xr.isSessionSupported('immersive-ar').then((supported) => {
       *      if (supported) {
       *        nativeAR();
       *      } else {
       *        eighthWallAR();
       *      }
       *    }).catch(eighthWallAR);
       *  }
       *
       */
    };
  }

  render() {
    const {
      model,
      biomes,
      loading,
      playing,
      fullscreen,
      compatible,
      memory,
    } = this.state;

    return (
      <>
        <BrowserView>
          {this.renderNonARBrowser()}
          <GlobeHome cameraTarget="side" globe={model} />
        </BrowserView>
        <MobileView>
          <Fullscreen
            enabled={fullscreen}
            onChange={(f) => this.setState({ fullscreen: f })}
          >
            {
              !playing
                ? (
                  <>
                    <GlobeHome cameraTarget="scene" globe={model} />
                    {
                      memory ? this.renderLowMemoryBrowser() : null
                    }
                    {
                      !compatible ? this.renderNonARBrowser() : null
                    }
                    {
                      compatible && !memory ? <Loading load={loading} onClickPlay={this.startExperience} /> : null
                    }

                  </>
                ) : (
                  <>
                    <GlobeAR globe={model} biomes={biomes} />
                    <Interface />
                  </>
                )
            }
          </Fullscreen>
        </MobileView>
      </>
    );
  }
}
