import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import ReactGA from 'react-ga';

import Button from '../Button/index';

import { getState } from '../../redux/selectors';
import { takePicture } from '../../redux/actions';


const Container = styled.div`
  z-index: 1020;
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 3rem;
  top: 1rem;
  left: 3rem;
  right: 3rem;

  justify-content: center;
  align-items: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;

  a {
    padding: 1rem;
    font-size: 1.2rem;
    line-height: 1rem;
    z-index: 1000;
    position: relative;
    background-color: var(--deep-blue);
    color: var(--sand);
    cursor: pointer;
    border-width: 0;
    outline-width: 0;
    text-decoration: none;
    font-weight: 400;
    border-radius: 0.25rem;

    &:active {
      background-color: var(--blue);
    }
  }

  a,
  button {
    flex: 0 0 calc(50% - 0.25rem);
    text-align: center;
    margin: 0 0.25rem;
  }
`;

const ImageContainer = styled.div`
  padding: 0.75rem;
  background: #fff;
  border-radius: 0.25rem;
  margin-bottom: 0.5rem;

  img {
    max-width: 100%;
    height: auto;
  }
`;

const CameraPicture = (props) => {
  const { image } = props;
  const date = new Date();
  const shareData = {
    title: 'Earth Day AR 2020',
    text: 'Celebrate the Earth Day 2020!',
    url: 'https://earthdayar.com',
  };

  const hasShare = (typeof navigator.canShare === 'function' && navigator.canShare(shareData)) || false;

  const share = async () => {
    const { navigator } = window;

    try {
      ReactGA.event({
        category: 'app',
        action: 'photo',
        label: 'share',
      });
      await navigator.share(shareData);
    } catch (err) {
      // console.log(err);
    }
  };

  return (
    <Container>
      <ImageContainer>
        <img alt="This is your snap" src={image} />
      </ImageContainer>
      <ButtonContainer>
        {
          hasShare ? (
            <Button square text="Share" onClick={share} />
          ) : null
        }
        <a
          href={image}
          title="Save"
          download={`EarthDayAR.com-${date.toISOString()}.jpg`}
        >
          Save
        </a>
      </ButtonContainer>
    </Container>
  );
};

export default connect(
  (state) => ({ store: getState(state) }),
  {
    takePicture,
  },
)(CameraPicture);
