import React, { Component } from 'react';
import styled from 'styled-components';

const SnapshotButton = styled.button`
  position: relative;
  background-color: transparent;
  cursor: pointer;
  border-width: 0;
  outline-width: 0;
  border-radius: 50%;
  transition: opacity 250ms linear;
  padding: 1.5rem;

  &:active {
    opacity: 0.9;
  }

  &::before {
    content: '';
    position: absolute;
    background: transparent;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    border: 0.15rem solid #fff;
  }

  &::after {
    content: '';
    position: absolute;
    background: #fff;
    top: 0.3rem;
    left: 0.3rem;
    right: 0.3rem;
    bottom: 0.3rem;
    border-radius: 50%;
  }

`;

export default class Snapshot extends Component {
  constructor() {
    super();

    this.onClick = () => {
      const { onClick } = this.props;
      if (onClick) {
        onClick();
      }
    };
  }

  render() {
    return (
      <SnapshotButton
        onClick={this.onClick}
        type="button"
      />
    );
  }
}
